<template>
  <Transition name="shrink">
    <div v-if="!success" class="py-4 sm:py-6 w-full bg-black-800 text-white">
      <div class="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto xl:text-lg flex flex-col  md:flex-row md:justify-between gap-2 md:items-center">
        <span class="">{{ $t('confirm.email.banner') }}</span>
        <button @click="handleSubmit" class="text-brand-200 font-bold">
          <span v-if="submitting">{{ $t('submitting') }}</span>
          <span v-else class="flex items-center gap-1 whitespace-nowrap">{{ $t('resend.email') }} <SvgChevronRight /></span>
        </button>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import {useCookieStore} from "~/store/cookie";
import {useAuthStore} from "~/store/auth";
import {storeToRefs} from "pinia";

const cookieStore = useCookieStore();
const authStore = useAuthStore();

const config = useRuntimeConfig();
const {user} = storeToRefs(cookieStore);
const {errors} = storeToRefs(authStore);
const submitting = ref(false);
const success = ref(false);

const handleSubmit = async () => {
  submitting.value = true;
  errors.value = null;
  await authStore.resendConfirmationEmail(user.value.username, config);
  submitting.value = false;
  if (errors.value === null) {
    if (user.value) {
      user.value.info.confirmed_email = true;
    }
    success.value = true;
  }

};
</script>

<style scoped>

</style>